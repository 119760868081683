const HomeImage = ({ title, image, linkMain, fontColor }) => {


    const textStyle = {
        position: 'absolute',
        // 'fontSize': '8vw',
        fontSize: 'min(8vw, 50px)',

        // overflow: 'hidden',

        top: '50%',
        left: '50%',

        color: fontColor,
        // backgroundColor: 'Red',
        transform: 'translate(-50%, -75%)',

        background: 'none',
        border: 'none',
        cursor: 'grab',
    }

    return (
        <>
            <img style={imgStyle} src={image} />
            <button onClick={linkMain}
                style={textStyle}>{title}</button>

        </>
    )
}




const imgStyle = {
    position: 'absolute',
    // overflow: 'hidden',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // height: "100%",
    minHeight: '100%',
    maxHeight: '150%',
    minWidth : '100%',
}


export default HomeImage