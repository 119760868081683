import { useNavigate } from 'react-router-dom';


const Naving = () => {
  const navigate = useNavigate();

  const navToHome = () => {
    navigate('/');
  };
  const navToProject = () => {
    navigate('/Projects');
  };

  const navToSkills = () => {
    navigate('/Skills');
  };
  // const navToTools = () => {
  //   navigate('/Tools');
  // };
  const navToHistory = () => {
    navigate('/History');
  };

  return (
    <div style={{
      zIndex: 2,
      position: 'relative',
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '98%',
      // backgroundColor: 'Red'

    }}>
      <button style={{
        // 'fontSize': '5vw',
        fontSize: 'min(5vw, 50px)',
        border: 'none',
        // color: 'Black',
        background: 'none',
        // padding: '10px',
        margin: '20px'

      }} onClick={navToHome} >Guy Boxall</button>


      <div style={{
        // backgroundColor: 'Blue',
        position: 'absolute',
        // left: '60%',
        right: '0%',
        top: '7%',
        'textAlign': 'right',


      }}>
        <button style={textStyle} onClick={navToHistory}>History</button>
        <button style={textStyle} onClick={navToProject} >Projects</button>
        {/* <button style={textStyle} onClick={navToTools}>Tools</button> */}
      </div>

    </div >
  )
}


const textStyle = {
  'marginTop': '0px',
  'marginRight': '1vw',

  'fontSize': '1em',
  border: 'none',
  background: 'none',
  cursor: 'grab',
}


export default Naving