import HalcyonProjections from '../images/HalcyonProjections.jpg'
import Python from '../images/Python.png'
// import Dink from './images/Dink.png'
import Industrial from '../images/Indusital_2.png'

import HomeImage from './HomeImage'
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring'
import { useState, useEffect } from 'react';


import './HomePage.css';




const HomePage = ({ }) => {
    const navigate = useNavigate();

    const navToProject = () => {
        navigate('/Projects');
    };
    const navToSkills = () => {
        navigate('/Skills');
    };
    const navToHistory = () => {
        navigate('/History');
    };

    const [{ pos }, set] = useSpring(() => ({ pos: 0.0 }));
    const [State, SetState] = useState(0);
    const [BackButtonText, SetBackButton] = useState('History');
    const [NextButtonText, SetNextButton] = useState('Skills');
    // console.log(State)




    useEffect(() => {
        // console.log(State);

        set({ pos: State });
        if (State == 0) {
            SetBackButton('History');
            SetNextButton('Skills');

        }
        else if (State == 1) {
            SetBackButton('Projects');
            SetNextButton('');


        }
        else if (State == -1) {
            SetBackButton('');
            SetNextButton('Projects');


        }
    });

    const handleBackClick = () => {
        SetState((State) => State - 1);
        // UpdateStates()
    };

    const handleNextClick = () => {
        SetState((State) => State + 1);
        // UpdateStates()

    };

    return (
        <>
            <animated.div
                className='imageFrame'
                style={{
                    transform: pos.interpolate(v => `translate(${v * -100}%, 0%)`),
                }}
            >
                <HomeImage
                    style={imgFrameStyle}
                    title={'Projects'}
                    image={HalcyonProjections}
                    linkMain={navToProject}
                    fontColor={'#1f1c1c'}
                />
            </animated.div>

            <animated.div
                className='imageFrame'
                style={{
                    transform: pos.interpolate(v => `translate(${v * -100 - 100}%, 0%)`),
                }}
            >
                <HomeImage
                    style={imgFrameStyle}
                    title={'History'}
                    image={Industrial}
                    linkMain={navToHistory}
                    fontColor={'white'}
                />
            </animated.div>

            <animated.div
                className='imageFrame'
                style={{
                    transform: pos.interpolate(v => `translate(${v * -100 + 100}%, 0%)`),
                }}
            >
                <HomeImage
                    style={imgFrameStyle}
                    title={'Skills'}
                    image={Python}
                    linkMain={navToSkills}
                    fontColor={'white'}
                />
            </animated.div>


            {/* ---------------Left-------------- */}
            <button className='NextImage'
                onClick={handleBackClick}
                style={{
                    // backgroundColor: 'Blue',
                    // height:'20em'
                    fontSize: 'min(3vw, 25px)',


                }}
            >{BackButtonText}</button>



            {/* ---------------Right--------------- */}

            <button className='NextImage'
                onClick={handleNextClick}
                style={{
                    position: 'absolute',
                    // 'fontSize': '30px',
                    top: '50%',
                    right: '0%',
                    left: 'auto',
                    'textAlign': 'right',
                    fontSize: 'min(3vw, 25px)',

                    // transform: 'translate(0%, -100%)',

                    // color: 'white',

                    // background: 'none',
                    // border: 'none',
                    // cursor: 'grab',

                    // backgroundColor: 'Red'

                }}>{NextButtonText}</button>
            {/* <h1 style={{ color: 'Black' }}>Home</h1> */}
        </>
    )
}

const imgFrameStyle = {
    position: 'absolute',
    top: '0%',
    bottom: '0%',
    right: '0%',
    left: '0%',
    // overflow: 'hidden',
    background: 'none',
    border: 'none',
}

const imgStyle = {
    // position: 'relative',
    position: 'absolute',
    height: '100%',
    transform: 'translate(-50%, -50%)',

}


export default HomePage
