import React, { Suspense, useEffect, useState } from "react";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { db } from "../firebase";
// import { onValue, ref } from "firebase/database";
import MeetTheTeam from "./meet_the_team";
import WeddingHome from "./WeddingHome";
import PhotoGallery from "./PhotoGallery";
import { TheDaysPlan, TheVenue } from "./the_plan";
import TravelInfo from "./TravelInfo";
import OurStory from "./Storey";



const Wedding = () => {

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
        link.href = 'https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Great+Vibes&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Playwrite+BE+VLG:wght@100..400&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        // Cleanup function to remove the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    // return null;
    return (
        <div style={{
            width: '100%',
            // height: '50rem',
            overflow: 'hidden',
            // marginBottom: '2px',
            backgroundColor: 'white',
            background: 'url(https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FPattern.jpg?alt=media&token=6cc1e7ad-6551-4f2c-9ea9-c1c8d03e2987)',

        }}>
            <WeddingHome />
            {/* <OurStory/> */}
            <PhotoGallery />
            <TheVenue />
            {/* <TheDaysPlan /> */}
            <TravelInfo />
            <MeetTheTeam />

        </div >
    )
}


export default Wedding