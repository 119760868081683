import { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// import { getStorage, ref, listAll } from "firebase/storage";
// import firebase from 'firebase/app';
// import 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCeMaqnvNR0Nr3QTGfI3PYC0cDTYpmL5rk",
    authDomain: "website-3de10.firebaseapp.com",
    databaseURL: "https://website-3de10-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "website-3de10",
    storageBucket: "website-3de10.appspot.com",
    messagingSenderId: "60438886884",
    appId: "1:60438886884:web:e924fa5682b2d9ce2d2ff1",
    measurementId: "G-CYFRM59CGN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);


export const useFirebaseStorageList = (path) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storage = getStorage();
        const listRef = ref(storage, path);

        listAll(listRef)
            .then(async (res) => {
                const folders = res.prefixes.map(folderRef => folderRef.name);
                const files = [];
                for (const itemRef of res.items) {
                    const downloadURL = await getDownloadURL(itemRef);
                    files.push({ name: itemRef.name, downloadURL });
                }
                // console.log(files)
                setItems({ folders, files });
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [path]);

    return { items, loading, error };
};

// export default useFirebaseStorageList;