// import { LazyLoadImage } from 'react-lazy-load-image-component';

const JobTitle = ({ data }) => {
    let Responsibility = data.Responsibility
    // { console.log(Responsibility) }


    return (<div>
        <div style={{
            // backgroundColor: 'red',
            display: 'grid',
            gridTemplateColumns: '2em auto',
            // marginBottom: '0em'

        }}>
            <div className='Line' style={{
                // backgroundColor: 'red',
                overflow: 'hidden',
            }} >
                <div className='vLine' style={{
                    backgroundColor: 'white',
                    height: '1px',
                    // width: '100%',
                    // margin: '100% 0% 0% 0%',
                    marginTop: '120%'
                }} />
                <div className='hLine' style={{
                    backgroundColor: 'white',
                    height: '65%',
                    width: '1px',
                    margin: '0% 0% 0% 95%',
                    // marginTop: '200%'
                }} />

            </div>
            <div style={{ margin: '1em' }}>
                <h2 style={{
                    fontSize: '2.5vh',
                    float: 'none',
                    marginBottom: '.2em',



                }}>{data.JobTitle}</h2>
                <p1 style={{
                    fontSize: '1.7vh',
                    // margin: '2px',
                    marginBottom: '20em',
                    // backgroundColor: 'red',



                }}>{data.Time}</p1>

                {Responsibility.map((string, key) => {

                    // { console.log(string) }
                    return (


                        <h3 style={{
                            // backgroundColor: 'red',
                            margin: '2px',
                            fontSize: '1.5vh',
                            fontWeight: 'normal',

                        }}
                            key={key.toString()}>
                            {string}
                        </h3>


                    );
                })}

            </div>
        </div >


    </div >)
}

const HFrame = ({ data }) => {
    let titles = data.Titles

    return (

        <div style={{
            // backgroundColor: 'red',
            display: 'grid',
            gridTemplateColumns: '2em auto',
            marginBottom: '2em'


        }}>
            <div style={{
                // backgroundColor: 'red',
                overflow: 'hidden',
            }} >
                <div className='hLine' style={{
                    backgroundColor: 'white',
                    height: '1px',
                    // width: '100%',
                    // margin: '100% 0% 0% 0%',
                    marginTop: '140%'
                }} />
                <div className='VLine' style={{
                    backgroundColor: 'white',
                    height: '80%',
                    width: '1px',
                    margin: '0% 0% 0% 95%',
                    // marginTop: '200%'
                }} />

            </div>
            <div style={{ padding: '1em' }}>


                <div style={{
                    // float: 'left',
                    // backgroundColor: 'red',
                    // verticalAlign: 'middle',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>

                    <img style={{
                        height: '5em',
                        margin: '1em',
                        float: 'left',


                    }}
                        loading="lazy"
                        src={"https://drive.google.com/uc?export=view&id=" + data.image} x
                    />
                    <h1 style={{
                        float: 'left',
                        fontSize: '3vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '1.5em',

                        // height: '3.5em',


                    }}>{data.Company}</h1>

                </div>

                <p1>{data.Time}</p1>
                {/* {console.log(data)} */}

                {titles.map((title, key) => {

                    // { console.log(title) }
                    return (

                        // <p1>{title.JobTitle}</p1>
                        <JobTitle key={key.toString()} data={title} />

                    );
                })}



            </div>
        </div >
    )
}


export default HFrame