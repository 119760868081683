import { LazyLoadImage } from 'react-lazy-load-image-component';

import Industrial from '../images/Indusital_2.png'
import useWindowDimensions from '../WindowSize'


const imgStyle = {
    // float: 'left',
    height: "98%",
    width: '98%',

    marginBottom: '2%',

    // margin: '1em',

    // height: '30%'

}


const VideoPlayer = ({ embedId }) => {


    if (embedId != "") {

        return (
            <iframe style={imgStyle}
                // width="100%"
                // height="100%"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        )
    }

}

const ProjectsInspector = ({ data }) => {
    const { height, width } = useWindowDimensions();

    let gridStyle
    if (height / width > 1) {
        gridStyle = {
            // backgroundColor: 'blue',
            margin: "1em",
            display: 'grid',
            gridTemplateColumns: '100%',
        }
    }
    else {
        gridStyle = {
            // backgroundColor: 'blue',
            margin: "1em",
            display: 'grid',
            gridTemplateColumns: '50% 50%',

        }
    }
    // let embedId = "GikO0uLmBSQ";



    return (
        <div style={{
            position: 'fixed',
            padding: '%',
            margin: '0',
            // marginTop: '10%',

            top: '0',
            left: '0',

            width: '100%',
            height: '100%',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0,0,0,.1)',

            zIndex: 11,

        }}>


            <div className="video-responsive">

            </div>



            <div className="ImageGallery"
                style={gridStyle}>

                <VideoPlayer embedId={data.Video} />

                {data.images.map((data, key) => {
                    return (

                        <img style={imgStyle}
                            loading="lazy"
                            src={"https://drive.google.com/uc?export=view&id=" + data} x
                        />

                    );
                })}
            </div>



        </div >



    )
}

export default ProjectsInspector
