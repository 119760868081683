import React, { Suspense, useEffect, useState } from "react";

import './photogrid.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { db } from "../firebase";
import { onValue, ref } from "firebase/database";


/// TODO Add local food to try
const TravelInfo = () => {

    const [projects, setProjects] = useState([]);
    useEffect(() => {
        const query = ref(db, "Wedding/TravelInfo");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            // console.log(data)
            if (snapshot.exists()) {
                Object.values(data).map((project) => {
                    setProjects((projects) => [...projects, project]);
                });
            }
        });
    }, []);


    return (

        <div style={{
            width: '100%',
            // height: '50rem',
            // backgroundColor: 'white',
            overflow: 'hidden',
            // marginBottom: '2px',
        }}>

            <div style={{
                margin: '3vh',
                backgroundColor: 'white',
                borderRadius: '2vh',
            }}>
                <h1>Travel Infromation</h1>

                <div class="infoGrid" >
                    <div>
                        <h2>Flights
                            <a>
                                Fly into Palermo Airport, just a 50-minute drive from the venue. While you can also fly into Catania Airport, be aware it’s a 3-hour drive away. To save on costs, book your tickets early, as prices rise closer to the departure date.                            </a>
                        </h2>
                        <h2>Transport
                            <a>
                                The best way to explore Sicily is by renting a car for flexibility. Public transport is available, including a train station in the town where we’re getting married, but it’s not within walking distance of the venue, and there are no buses. Taxis can be booked in advance from the airport (an hour away), and Ubers are available in Palermo. If you’re concerned about driving or unsure how to get around, let us know, and we’ll help find a solution.
                            </a>
                            <h3>
                                Renting a Car
                                <a href='https://www.rentalcars.com/'
                                    target="_blank">
                                    We use rentalcars.com and have rented cars through Sicily by Car and Goldcar without any issues. We would recommend booking as soon as possible but checking 2-4 weeks before, as the prices can be much lower and you can rebook for free.
                                </a>
                            </h3>
                            <h3>
                                Insurance
                                <a href='https://www.icarhireinsurance.com/'
                                    target="_blank">
                                    We usually get car insurance from icarhireinsurance.
                                </a>
                            </h3>
                            <h3>
                                Parking
                                <a>
                                    Parking is categorised by different coloured parking zones:
                                </a>
                                <a>
                                    - **No color marking** indicates free parking, but make sure there is no towing sign.
                                </a>
                                <a style={{ color: '#7a7a7a' }}>
                                    - **White marking** signifies free parking (except in Cefalù), where it's reserved for locals.
                                </a>
                                <a style={{ color: '#0069d1' }}>
                                    - **Blue zones** are for paid parking, and you can use the EasyPark app for payment and to find parking locations.
                                </a>
                                <a style={{ color: '#ffbb00' }}>
                                    - **Orange/Yellow zones** are strictly for permit holders/locals and should be avoided.
                                </a>
                            </h3>
                            <h3>
                                Gifts
                                <a>
                                    Your presence at our wedding is the greatest gift of all!

                                    Should you wish to honour us with a gift, a monetary contribution towards our future home would be deeply appreciated.
                                </a>
                            </h3>

                        </h2>
                        <h2>Day Trips

                            <h3
                                href='https://maps.app.goo.gl/RBwQtN6kS5daVy9K7'
                                target="_blank"

                            >
                                Cefalù
                                <a
                                    href='https://maps.app.goo.gl/RBwQtN6kS5daVy9K7'
                                    target="_blank">
                                    This town boasts a beautiful beach and a charming town centre worth exploring. For stunning views, hike to the top of the hill, but be sure to wear trainers, as they’re required for access.                                </a>
                                <LazyLoadImage className='infoImage'
                                    src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FInfo%2FCefal%C3%B9.webp?alt=media&token=8981d068-1646-4546-943a-0e1de59a08e8"}
                                />
                            </h3>
                            <h3 href='https://maps.app.goo.gl/A4aVrUE4QV71CqDy'
                                target="_blank">
                                Termini Imerese
                                <a
                                    href="https://maps.app.goo.gl/byoirJfM2DtGZDjx8"
                                    target="_blank">
                                    Local must visit Market only on Fridays from 7 a.m. until 12 p.m. at Termini Imerese
                                </a>
                            </h3>
                            <h3 href='https://maps.app.goo.gl/vnL8aEaAMqVPe3e26'>
                                Palemo Street Food Market (ballarò)
                                <a>
                                    The Ballarò Market is open daily from 7am to 7pm, but it is especially lively on Saturdays and Sundays when the weekly flea market is also open and food stands prepare traditional street food from Palermo
                                </a>
                            </h3>
                            <h2 href="mailto:boxallwedding2025@gmail.com?subject=Names%20Of%20People&body=Please List out the people attending as well as any allergies you may have" >
                                RSVP



                                <a href="mailto:boxallwedding2025@gmail.com?subject=Names%20Of%20People&body=Please List out the people attending as well as any allergies you may have"  >
                                    Please Email us the folowing infromation:
                                </a>
                                <a href="mailto:boxallwedding2025@gmail.com?subject=Names%20Of%20People&body=Please List out the people attending as well as any allergies you may have" >
                                    Guest names:<br />
                                    If you are attending: Yes/No<br />
                                    The main meal will be fish-based; however, would you prefer: (Fish, Meat, or Vegetarian):<br />
                                    Any dietary requirements:<br />
                                    @<br />
                                </a>
                                <a href="mailto:boxallwedding2025@gmail.com?subject=Names%20Of%20People&body=Please List out the people attending as well as any allergies you may have" >
                                    boxallwedding2025@gmail.com
                                </a>
                            </h2>
                        </h2>


                    </div>
                    <div>

                        <h2>Accommodation
                            <a>
                                You have several options for booking accommodations, and we prefer using Airbnb or booking.com. Regardless of where you book, it's advisable to ensure that air conditioning is available. We would book somewhere around the town of Trabia, as there are a lot of villas in the surrounding towns.
                            </a>
                        </h2>
                        <h2>Local Pleases to Visit
                            <h3>
                                Sicilian Food you must Try!
                                <a>
                                    Palermo is famous for its street food, including arancini (fried rice balls), panelle (chickpea fritters), sfincione (Sicilian pizza), and pane con la milza (spleen sandwich), Stigghiola (lamb, goat or pork intestine)
                                </a>
                                <LazyLoadImage className='infoImage'
                                    src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2Fsicily_3.jpeg?alt=media&token=7f04525b-de85-4d28-a304-9176badc67c0"}
                                />
                            </h3>

                            <h3>Local food recommendations

                                <a href='https://maps.app.goo.gl/ymHve84dx6UYKWCT7'
                                    target="_blank">
                                    Lido Di Vetrana<br />
                                    The best local pizza and seafood<br /><br />
                                    <LazyLoadImage className='infoImage'
                                        src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FInfo%2FLido_Pizza.jpeg?alt=media&token=11e8177f-ece6-49a8-b838-7f6c95897cec"}
                                    />

                                </a>
                                <a href='https://maps.app.goo.gl/qqnHup4jyekBeckZ6'
                                    target="_blank">
                                    Il Delfino Ristorante Trattoria<br />
                                    Unlimited Seafood and white wine for $35. This plase is 20 mins from the airport.<br /><br />
                                    <LazyLoadImage className='infoImage'
                                        src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FInfo%2FDelp_Oct.jpeg?alt=media&token=4ebdf415-8dd2-40b8-b41b-e6f5f96527fahttps://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FInfo%2FLido_Pizza.jpeg?alt=media&token=11e8177f-ece6-49a8-b838-7f6c95897cec"}
                                    />

                                </a>
                                <a href='https://maps.app.goo.gl/LbezbDsBPqMf1ZoaA'
                                    target="_blank">
                                    Gelateria Cicciuzzu Trabia<br />
                                    Best local ice cream. Guy's personal favourite is the dark chocolate.<br /><br />
                                </a>
                                <LazyLoadImage className='infoImage'
                                    src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FInfo%2FWhatsApp%20Image%202024-08-10%20at%2010.09.59.jpeg?alt=media&token=e7bf149e-c7cc-4c52-95a2-27631c498d62"}
                                />

                                {/* <a href='https://maps.app.goo.gl/U3xKxMbrBPg4ABNu7'
                                    target="_blank">
                                    Link To Local beach in Trabia parking
                                    <br />

                                </a> */}
                            </h3>
                        </h2>
                        <h2>Feeling Adventures?

                            <a>If you want to travel and see the most of Sicily, here are some locations we recommend visiting.</a>

                            <h4>Palemo North West</h4>
                            <a>
                                - Mo dello beach <br />
                                - Isola delle femmine Beach <br />
                                - Riserva Dello Zingaro <br />
                                - Palermo Cathedral <br />
                                - San Vito Lo Capo Beach <br />
                                - Quattro Canti (This baroque square is the heart of Palermo) <br />

                            </a>


                            <h4>South East </h4>
                            <a>
                                - Catania<br />
                                - Noto<br />
                                - Syracuse<br />
                                - Marzamemi<br />
                                - Fontane Bianche Beach (Near Syracuse, Southeastern Sicily) <br />
                                - Marina di Ragusa Beach (Southern Sicily) <br />
                                - Cala Mosche Beach(Vendicari Nature Reserve, Southeastern Sicily). Good for snorkeling <br />
                            </a>

                            <h4>North East</h4>
                            <a>
                                - Taormina <br />
                                - Isola Bella <br />
                                - Messina  <br />
                                - Catana<br />
                            </a>

                            <h4>South West</h4>
                            <a>
                                - Agrigento (ruins of the ancient city)<br />
                                - Scala dei Turchi Beach (Near Agrigento, Southerin Sicily) <br />
                                - Selinunte (ancient Greek city of Magna Graecia) <br />
                                - Saline of the Laguna Marsala (The best Sunsets over looking over salt fields)


                            </a>






                        </h2>

                    </div>




                </div>



            </div>

        </div >)
}

export default TravelInfo
