import React, { Suspense, useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { db } from "../firebase";
import { onValue, ref } from "firebase/database";
import './photogrid.css';
// import useWindowDimensions from "../GetWindowDimentio";


const MeetTheTeam = () => {

    const [projects, setProjects] = useState([]);
    // const { height, width, isDesktop } = useWindowDimensions();

    useEffect(() => {
        const query = ref(db, "Wedding/TheTeam");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).map((project) => {
                    setProjects((projects) => [...projects, project]);
                });
            }
        });
    }, []);

    return (
        <div style={{
            margin: '3vh',
                backgroundColor: 'white',
                borderRadius: '2vh',
        }}>
            <h1>Meet the Team</h1>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                marginLeft: "1vh",
                marginRight: "1vh",
                gridGap: '1rem',
                backgroundColor: "rgba(255, 255, 255,.5",

            }}>
                {projects.map((project, index) => (
                    <div style={{
                    }}>

                        <LazyLoadImage style={{
                            overflow: 'hidden',
                            width: '100%',
                            borderRadius: '1vh',

                        }}
                            effect="blur"
                            // loading="lazy"
                            src={project.Image}
                        />

                        <div style={{
                            gridRow: '2/2',
                            color: 'black',
                            textAlign: 'center',
                            fontSize: '2.1vh',

                            // fontFamily: 'Playwrite BE VLG, sans-serif',
                            
                        }}>{project.Name}</div>

                        <div style={{
                            gridRow: '3/3',
                            color: 'black',
                            textAlign: 'center',
                            fontSize: '1.3vh',
                        }}>{project.details}</div>
                        <div style={{
                            gridRow: '3/3',
                            color: 'black',
                            textAlign: 'center',
                            fontSize: '1.3vh',

                        }}>{project.Language}</div>
                    </div>
                ))}

            </div>
            <div style={{
                height: '1rem'
            }} />
        </div >
    )
}


export default MeetTheTeam