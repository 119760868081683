import React, { Suspense, useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { db } from "../firebase";
import { onValue, ref } from "firebase/database";
import './photogrid.css';


const WeddingHome = () => {
    const scrollToSection = () => {
        const section = document.getElementById('Venu');
        section.scrollIntoView({ behavior: 'smooth' });
    };


    return (

        <section style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            height: '100vh',
            overflow: 'hidden',



        }}>
            <img
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FSunset.jpeg?alt=media&token=49837ec5-4ca2-4772-a348-a275011262bd"}
            />
          
            <div style={{
                position: 'absolute',
                top: '20%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                textAlign: 'center',
                color: 'white',
                // fontWeight: 'bold',
                // fontFamily: 'Dancing Script', // Set font to Dancing Script
                maxWidth: '90%', // Limiting text width to 90% of the screen width
                fontSize: '8vw', // Set font size as a percentage of viewport width
                lineHeight: 1.2, // Adjust line height as needed
                whiteSpace: 'nowrap', // Preventing text from wrapping
                overflow: 'hidden', // Hide overflow text
                textOverflow: 'ellipsis',
                fontFamily: 'Great Vibes, cursive',

            }}>
                Serena & Guy
            </div>
            <div style={{
                position: 'absolute',
                top: '30%',
                left: '50%',
                transform: 'translate(-50%, 400%)',
                textAlign: 'center',
                color: 'white',
                // fontWeight: 'bold',
                // fontFamily: 'Dancing Script', // Set font to Dancing Script
                // fontFamily: 'Great Vibes, cursive',
                // fontFamily: 'DM Serif Display, serif',
                fontFamily: 'Great Vibes, cursive',


                maxWidth: '90%', // Limiting text width to 90% of the screen width
                fontSize: '3vw', // Set font size as a percentage of viewport width
                lineHeight: 1.2, // Adjust line height as needed
                whiteSpace: 'nowrap', // Preventing text from wrapping
                overflow: 'hidden', // Hide overflow text
                textOverflow: 'ellipsis'
            }}>
                Please join us on the 22 June 2025 at our destination Wedding in Sicily!
            </div>
            <a style={{
                position: 'absolute',
                top: '70%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '3vh',
                color: 'white',
                fontFamily: 'Great Vibes, cursive',
                textDecoration: 'none'

            }}
                href="mailto:boxallwedding2025@gmail.com?subject=Names%20Of%20People&body=Please List out the people attending as well as any allergies you may have" >
                RSVP
            </a>

            <img
                style={{
                    position: 'absolute',
                    top: '95%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    opacity: '0.3',
                    width: '5vh',
                    // hight: '10vh',
                    animation: 'sineWaveOpacity 3s infinite', // Apply the animation



                }}
                src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Arrow.png?alt=media&token=c6dc8de4-cd2a-4feb-9c3b-dc7b951e4413"}
                onClick={scrollToSection}
            />

        </section >


    )
}

export default WeddingHome



