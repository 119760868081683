import './App.css';
import Projects from './components/Projects/Projects';
import ProejctsHome from './components/Projects2/Proejcts_Home';

import Naving from './components/Naving';
import History from './components/History/History';
import Skills from './components/Skills/Skills';
import HomePage from './components/Home/HomePage';
import Wedding from './components/Wedding/Wedding_Page';
import ToolsHome from './components/Tools/Tools_Home';

import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'

// import RedirectToWedding from './components/Wedding_Redurect';

function HideNavi() { // Hide Nav Bard on Wedding Page 
  const location = useLocation();
  const isWeddingPage = location.pathname.includes('/wedding');
  // console.log(isWeddingPage)

  return !isWeddingPage && <Naving />;
}

function App() {
  return (
    <Router>
      {/* <Naving /> */}
      <HideNavi />

      <Routes>
        <Route exact path="/" element={<HomePage />} />
        {/* <Route exact path="/Projects" element={<Projects />} /> */}
        <Route exact path="/Projects" element={<ProejctsHome />} />

        {/* <Route exact path="/Skills" element={<Skills />} /> */} 
        {/* <Route exact path="/Tools" element={<ToolsHome />} /> */}

        <Route exact path="/History" element={<History />} />
        {/* <Route exact path="/Wedding" element={<RedirectToWedding />} /> */}
        <Route exact path="/Wedding" element={<Wedding />} />

        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Router>

  );
}

export default App;
