import React from 'react';
import './photogrid.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const TheDaysPlan = () => {
    return (

        <div id="DaysPlan">

            <div style={{
                margin: '1vh 10vh',
                backgroundColor: 'white',
                borderRadius: '2vh',

            }}>
                <h1>The Days Plan</h1>
                <h2>22 June 2025</h2>
                <a>4:00 Welcome Drinks</a>
                <a>5:00 Cermony</a>
                <a>6:00 Wedding Part Leves for photos</a>
                <a>7:00 Aperole Spritze & Canapes</a>
                <a>8:00 Bride & Groom Entrance</a>
                <a>8:30 Starter Buffet</a>
                <a>10:00 Move to Pool</a>
                <a>10:30 3 Main Courses & Dancing</a>
                <a>12:00 Speeches</a>
                <a>12:30 Cake Cutting</a>
                <a>12:45 Desert Buffet </a>
                <a>2:00 the end</a>
            </div>


        </div >)
}


const TheVenue = () => {
    return (
        <section id="Venu" style={{
            width: '100%',
            // backgroundColor: 'white',

            overflow: 'hidden',
            // margin: '20px',
        }}>
            <div style={{
                margin: '1vh',
                backgroundColor: 'white',
                borderRadius: '2vh',


            }}>

                <h1>The Venue</h1>

                <a>
                    Please arrive at La Fenice at 4pm on Sunday 22 June 2025.
                </a>
                <a>
                    The ceremony and reception will both take place at the same venue, which offers plenty of free parking
                </a>
                {/* <a>
                    If you have any quetion please feel free to messagse or call us we are more than happy to help
                </a> */}
                <h3>
                    Dress Code:
                </h3>
                <a>
                    We kindly ask that you avoid wearing white, baby blue, or turquoise.
                </a>

                {/* <img
                style={{
                    // position: 'absolute',
                    width: '6vh',
                    height: '6vh',
                    display: 'flex',
                    textAlign: 'center',
                    display: 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/GoogleMapsIcon.png?alt=media&token=6c1df5cc-d978-4eda-9f9f-b8621b54f81a"}/>              */}
                <a style={{
                    fontSize: '2vh',
                    // backgroundColor: 'white',
                    // width: '30%'

                }}
                    href='https://maps.app.goo.gl/WS8my6L2RPtfHgnj9'
                    target="_blank"
                >La Fenice Receptions,<br />

                    Contrada Petruso,<br />

                    90019 Trabia PA,<br />

                    Italy</a>

                <div className="gallery">
                    <div className='one'>
                        <LazyLoadImage className='img'

                            threshold={500}
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FVenu_1.png?alt=media&token=c7dd9997-386c-49d8-a29f-b81df43d7175"}

                        />
                    </div>
                    <div className='two'>
                        <LazyLoadImage className='img' threshold={500}
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FVenu_Entrence.jpeg?alt=media&token=e914a594-30e4-41cc-8cab-cfa0700a1068"}
                        />
                    </div>
                    <div className='three'>
                        <LazyLoadImage className='img' threshold={500}
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FVenu_4.jpg?alt=media&token=be90d1b7-7da7-4ef3-a70c-54bdd0cc8458"}
                        />

                    </div>

                    <div className='four'>
                        <LazyLoadImage className='img' threshold={500}
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FVenu9.jpeg?alt=media&token=30e7d806-86b4-49f6-a38d-872e9e871cdc"}

                        />
                    </div>
                </div>
            </div>

        </section>)
}
export { TheDaysPlan, TheVenue };
