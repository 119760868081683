import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './photogrid.css';

const PhotoGallery = () => {
    return (
        <div style={{
            width: '100%',
            // backgroundColor: '#ffd54c',// Yellow
            // backgroundColor: '#afe6ff', // Blue
            // backgroundColor: 'white',

            overflow: 'hidden',
            // marginBottom: '2px',
        }}>
            <div style={{
                // margin: '10px auto',
                // maxWidth: '1200px',
                // backgroundColor: 'blue',

                // padding: '0 16px',
                margin: '1vh',
                backgroundColor: 'white',
                borderRadius: '2vh',
            }}>
                <div className="gallery">
                    <div className='one'>
                        <LazyLoadImage className='img'
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FG%26S%2FBuda_Tiland.jpeg?alt=media&token=741a4ffc-e988-4e4f-82db-9c495c80481e"}
                        />
                    </div>
                    <div className='two'>
                        <LazyLoadImage className='img'
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FG%26S%2FTiland_Elefents.jpeg?alt=media&token=dd53c2fc-08fe-4c9e-b6cb-915f3a2c06fc"}
                        />
                    </div>
                    <div className='three'>
                        <LazyLoadImage className='img'
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FG%26S%2FGrandC_Selfie.jpeg?alt=media&token=277757b5-0c62-4b6c-af7d-ad576d66f506"}
                        />
                    </div>
                    <div className='four'>
                        <LazyLoadImage className='img'
                            src={"https://firebasestorage.googleapis.com/v0/b/website-3de10.appspot.com/o/Wedding%2FG%26S%2FTiland_View.jpeg?alt=media&token=0be2d449-0665-430d-8d00-388de9d6809c"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PhotoGallery;
