import React, { Suspense, useEffect, useState } from "react";
import { db, useFirebaseStorageList } from "../firebase";
import { onValue, ref } from "firebase/database";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProjectFrame from "./ProjectFrame";




const ProjectsHome = () => {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        const query = ref(db, "Projects");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).map((companys) => {
                    setProjects((projects) => [...projects, companys]);
                });
                // Sort by date
                setProjects((projects) => projects.sort((a, b) => new Date(a.date) - new Date(b.date)));
                setProjects((projects) => projects.reverse())
            }
        });

    }, []);


    return (
        <div style={{
        }}>
            {/* <h1>Projects</h1> */}
            <div style={{
                display: 'grid',
                // gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                // gridTemplateColumns: 'auto auto auto',
                // gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
                gridTemplateColumns: 'repeat(auto-fit, minmax(25rem, 1fr))',


                // marginLeft: "1rem",
                // marginRight: "1rem",
                gridGap: '2px',
                // backgroundColor: "rgba(255, 255, 255,.5)",
            }}>
                {projects.map((project, i) => (
                    
                        
                            <ProjectFrame key={i} projectInfo={project} />
                      

                    
                ))}
            </div>


        </div>

    )
}

export default ProjectsHome
