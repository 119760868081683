import { LazyLoadImage } from 'react-lazy-load-image-component';
// import ProjectsInspector from "./ProjectInspector"
import { useState, useEffect, forwardRef } from 'react';
import { db, useFirebaseStorageList } from "../firebase";
import './projects.css';



const Image = ({ downloadURL, SetEnable }) => {

    const [ShowFullScreenImage, SetState] = useState(false);

    const ToggleFullScreenImage = () => {
        SetState((ShowFullScreenImage) => !ShowFullScreenImage);
    };
    let imgCSS
    let frameCSS

    if (ShowFullScreenImage) {

        imgCSS = {
            width: '100%',
            height: 'auto',
            // objectFit: 'cover',
            // top: '0',
            // left: '0',
        }

        frameCSS = {
            // position: "absolute",
            position: 'fixed',
            zIndex: 9999,
            top: '10',
            left: '0',
            // width: "100%",
            // hight: "100%",
            // bottom:"0%",
            width: '100vw', /* 100% of viewport width */
            height: '100%', /* 100% of viewport height */
            backdropFilter: 'blur(50px)',
            display: 'flex',

        }
    } else {

        imgCSS = {
            width: '100%',
            Height: '100%',
            display: 'block',

        }
        frameCSS = {
            overflow: 'hidden',
            display: 'block',
            zIndex: 5,
        }

    }







    return (
        <div style={frameCSS}
            onClick={ToggleFullScreenImage}>
            <LazyLoadImage style={imgCSS}
                // effect="blur"
                // loading="lazy"
                src={downloadURL} />
        </div>



    )
}


const ProjectsInspector = ({ projectInfo, ToggleInspector, SetEnable }) => {

    const { items, loading, error } = useFirebaseStorageList(projectInfo.images);
    return (

        <div style={{
            position: "absolute",
            zIndex: 2,
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backdropFilter: 'blur(50px)',
        }}
        // onClick={ToggleInspector}
        >
            <h1 style={{
                color: 'white',
            }}
            >{projectInfo.projectName}</h1>

            <a>{projectInfo.date}</a>
            <a>{projectInfo.description}</a>
            <a>{projectInfo.images}</a>
            <a>{projectInfo.Video}</a>

            {loading && <a>Loading...</a>}

            {error && <a>Error: {error.message}</a>}

            {!loading && !error && (
                <div style={{
                    display: 'grid',
                    gridGap: '0.5rem',
                    // zIndex: 5,

                    // gridTemplateColumns: 'auto auto',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',

                    margin: '1rem',

                }}>

                    {items.files && items.files.map(file => (
                        <Image downloadURL={file.downloadURL} SetEnable={SetEnable} />
                    ))}
                </div>

            )}

            {/* {inspector} */}


        </div>

    )
}

const ProjectFrame = ({ projectInfo }) => {


    const [ShowInspector, SetState] = useState(false);
    const [EnableToggleShowInspector, SetEnable] = useState(true);



    const ToggleInspector = () => {
        console.log(EnableToggleShowInspector)
        if (EnableToggleShowInspector) {
            SetState((ShowInspector) => !ShowInspector);
            console.log("Open Project Inspector")
        }
    };
    let inspector
    if (ShowInspector) {
        inspector = <ProjectsInspector projectInfo={projectInfo} ToggleInspector={ToggleInspector} SetEnable={SetEnable} />
    }
    return (
        <div className="container" style={{
            position: 'relative',
            height: '30rem',
            overflow: 'hidden',
            // width: '20rem',
            // backgroundColor: 'red'
        }}>
            <LazyLoadImage style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transform: 'translate(-50%, -50%)',
            }}
                // effect="blur"
                // loading="lazy"
                src={projectInfo.image}
            // onClick={ToggleInspector}

            />
            {/* <div className="overlay">
                <h1>
                    {projectInfo.projectName}
                </h1>
                <h2>
                    {projectInfo.ShortD}
                </h2>

            </div> */}
            {/* {inspector} */}




        </div>
    )
}

export default ProjectFrame
