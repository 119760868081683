
import HFrame from './HistoryFrame';

const boxStyle = { backgroundColor: '', width: '300px', margin: '100px' };
const lineColor = { borderColor: 'Blue', borderStyle: '' };
const History = () => {
    let History = require('../data/History.json');

    return (
        <div style={{}}>
            <div className="grid" style={{
                // width: '2%'
                display: 'grid',
                // gap: '50px',
                // backgroundColor: 'orange',
                gridTemplateColumns: '5% auto',
                // textAlign: 'center',
            }}>


                <div className="vLine" style={{
                    backgroundColor: 'white',
                    // height: '100%',
                    // width: '5%',
                    width: '1px',
                    marginLeft: '95%',
                    marginTop: '2em',
                    marginBottom: '3em'
                }} />


                <div style={{
                    // backgroundColor: 'purple',
                }}>
                    {History.map((data, key) => {

                        return (

                            <HFrame key={key.toString()} data={data} />

                        );
                    })}

                </div>
            </div>

            <div className="Bottom" style={{
                width: '2%'
            }}></div>

        </div>
    )
}


export default History